.App {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  /*
  justify-content: center;
  */
  font-size: calc(10px + 2vmin);
  color: white;
}

.conversation-block {
  background-color: #180f20;
  margin: 10px 0;
  padding: 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  border: thin solid #bbd;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-group {
    width: 80%;
    max-width: 800px;
    min-height: 100vh;
}

.card-text {
    font-size: calc(8px + 2vmin);
    color: #ccc;
}

.card-title {
    text-align: center;
    font-size: calc(13px + 2vmin);
    color: #fd8
}

.question-button {
    margin: 3px;
    padding: 3px;
    font-size: calc(5px + 2vmin);
}

.btn-success {
    background: #2d2;
}

.paragraphs {
    background: blue;
}
